import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPad/Install/Others/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer iPhone oder iPad App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_iOS.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_iOS.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "ipad-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ipad-app-installationsassistent",
        "aria-label": "ipad app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPad App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera-andere-modelle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera-andere-modelle",
        "aria-label": "hinzufügen einer neuen ip kamera andere modelle permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera (`}<em parentName="h3">{`Andere Modelle`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/49853/iPad_Wizard_Installation_Others_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABO0lEQVQoz5WS227jMAxE/f+/mS0Q1JYVkhJvoqwi2cZNnbZoB+eJwkCUZqbzcpnn9O90SimpaqmaazfvrV1xj4gNsSCWiO3/MGIrVE/nPF2QmFlVmblFiHpGK9XqHWYHKACV2fehsGVq07qmlNK4K1oQCsAnEBVRPw8ZgScAKKWMMbabWgtE+SVTzpmIxhi99y/NRLrzhTmltN3VbmsXErodA/CywDzneb4sCwDwI1NEuPtu7r0vqOesC6iqubdapRQuhWtl99gRjWk8Catl0lKlNR/fq8WYtqN6LjaDqAgzi8h3Zn82R8R1SWLEK0RcHzJ/pFQ7mp9/+5D5I8c3R/Q/5PzDzdca/ca8N6xH1HvIh2IQfdTm3WxmIpJSqpWb2yvYy+qJ3L2t6yVnEFEzByCiauYiilhEzMzfADcHZEx8LpnjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e732e915e1b7ee0b41ef3bf44956ff88/e4706/iPad_Wizard_Installation_Others_01.avif 230w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/d1af7/iPad_Wizard_Installation_Others_01.avif 460w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/7f308/iPad_Wizard_Installation_Others_01.avif 920w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/e1c99/iPad_Wizard_Installation_Others_01.avif 1380w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/76ea5/iPad_Wizard_Installation_Others_01.avif 1840w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/b105c/iPad_Wizard_Installation_Others_01.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e732e915e1b7ee0b41ef3bf44956ff88/a0b58/iPad_Wizard_Installation_Others_01.webp 230w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/bc10c/iPad_Wizard_Installation_Others_01.webp 460w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/966d8/iPad_Wizard_Installation_Others_01.webp 920w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/445df/iPad_Wizard_Installation_Others_01.webp 1380w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/78de1/iPad_Wizard_Installation_Others_01.webp 1840w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/03b06/iPad_Wizard_Installation_Others_01.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e732e915e1b7ee0b41ef3bf44956ff88/81c8e/iPad_Wizard_Installation_Others_01.png 230w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/08a84/iPad_Wizard_Installation_Others_01.png 460w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/c0255/iPad_Wizard_Installation_Others_01.png 920w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/b1001/iPad_Wizard_Installation_Others_01.png 1380w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/161ec/iPad_Wizard_Installation_Others_01.png 1840w", "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/49853/iPad_Wizard_Installation_Others_01.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e732e915e1b7ee0b41ef3bf44956ff88/c0255/iPad_Wizard_Installation_Others_01.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Menü `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie den `}<strong parentName="li">{`Installationsassistenten`}</strong>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f1d94c38191b8b05616f7440026b68e8/49853/iPad_Wizard_Installation_Others_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVQoz32QW2vbQBCF9cfbl2AKJeSHtNA+BxyaPrUPvefBlKSRJVmxLGnvl9mZXVlFdmxiaHo4DMPZ/ZjZzZpedh1bVRVjLIRgfRBugJCIJsc4OOc3m7breucgxoEoxjgYY8uGZ1Ib730IwXufUoJA0hJAQkwhRMSktdts+rbttbaIQwgxYCIclEsZ54wxNh6U0tB3oizXVbUuioeqau7vqzxf5flUq6qpqqYo6rJcd63IlFLW2nEch2G73W5jTHW9yfNVUdR5vlou67J8WCzuFovb5fIxyfNquVzVdZsJIYwxO3jYw9aic3S099GYYC16H5/m1uIEM8a2B8WYtIajlfJSWqU857br5NNca8hSSkT0DBwE1/dlv1rzm1+LT5+/9J00JhwvZOOpUhpOJktXN6LZ8N+3xdebP30nTuDtqY6TpQKuQCjoes2FY8I1rZI6CDUd/RdWkNCPaW8Yo5+cYJ+k4J+FlQZn4c03fHEZZ1fxbB5fXU0+m9PsKr68jG+/o7eg/vnmCTbw7gfO5nR+Ta8/0MVHutg159c0m9P7nwf4ubVj2K1Kh7pvdo5wuvY4jvtmSMkZMBqkBKmCVEFIEKe90o8fniEiADDGnPORsFVYc2KGiCLnUggFEBBJKWOMQySAoLUFQET6Cx1nTvgdZoBiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f1d94c38191b8b05616f7440026b68e8/e4706/iPad_Wizard_Installation_Others_02.avif 230w", "/en/static/f1d94c38191b8b05616f7440026b68e8/d1af7/iPad_Wizard_Installation_Others_02.avif 460w", "/en/static/f1d94c38191b8b05616f7440026b68e8/7f308/iPad_Wizard_Installation_Others_02.avif 920w", "/en/static/f1d94c38191b8b05616f7440026b68e8/e1c99/iPad_Wizard_Installation_Others_02.avif 1380w", "/en/static/f1d94c38191b8b05616f7440026b68e8/76ea5/iPad_Wizard_Installation_Others_02.avif 1840w", "/en/static/f1d94c38191b8b05616f7440026b68e8/b105c/iPad_Wizard_Installation_Others_02.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f1d94c38191b8b05616f7440026b68e8/a0b58/iPad_Wizard_Installation_Others_02.webp 230w", "/en/static/f1d94c38191b8b05616f7440026b68e8/bc10c/iPad_Wizard_Installation_Others_02.webp 460w", "/en/static/f1d94c38191b8b05616f7440026b68e8/966d8/iPad_Wizard_Installation_Others_02.webp 920w", "/en/static/f1d94c38191b8b05616f7440026b68e8/445df/iPad_Wizard_Installation_Others_02.webp 1380w", "/en/static/f1d94c38191b8b05616f7440026b68e8/78de1/iPad_Wizard_Installation_Others_02.webp 1840w", "/en/static/f1d94c38191b8b05616f7440026b68e8/03b06/iPad_Wizard_Installation_Others_02.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f1d94c38191b8b05616f7440026b68e8/81c8e/iPad_Wizard_Installation_Others_02.png 230w", "/en/static/f1d94c38191b8b05616f7440026b68e8/08a84/iPad_Wizard_Installation_Others_02.png 460w", "/en/static/f1d94c38191b8b05616f7440026b68e8/c0255/iPad_Wizard_Installation_Others_02.png 920w", "/en/static/f1d94c38191b8b05616f7440026b68e8/b1001/iPad_Wizard_Installation_Others_02.png 1380w", "/en/static/f1d94c38191b8b05616f7440026b68e8/161ec/iPad_Wizard_Installation_Others_02.png 1840w", "/en/static/f1d94c38191b8b05616f7440026b68e8/49853/iPad_Wizard_Installation_Others_02.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f1d94c38191b8b05616f7440026b68e8/c0255/iPad_Wizard_Installation_Others_02.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie finden den `}<strong parentName="li">{`QR-Code`}</strong>{` in der `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Internet_Access/"
        }}>{`WebUI`}</a>{` (A), auf dem Etikett Ihrer Kamera (B) oder auf der Verpackung abgedruckt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/49853/iPad_Wizard_Installation_Others_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAyklEQVQoz62S3WqEQAyFJ5k1Y36G0VQUvRF8/4csjHRxi4tu6XeZ5JCTcEJ4EBCHP4IR4iP8L3Dg1jQztz+4+zAMXxURedZjjGf2EVUVEWMFEQEAK88KEbVtey4+bxyIMaaUrsWI2FSOPq/FXBnHcV3XbdumaZLKLXHO2d1LKSKSczazvu/d/QPbH9/MzJfi8wUAYGbMbGYiwq/sLRFpmuZtThBxWRYi+mVqnueU0nXa9hgxcyml6zoz20+9ldMQAhGpqlVU9d0vvgHlHwsUrtTI2gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/e4706/iPad_Wizard_Installation_Others_03.avif 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/d1af7/iPad_Wizard_Installation_Others_03.avif 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/7f308/iPad_Wizard_Installation_Others_03.avif 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/e1c99/iPad_Wizard_Installation_Others_03.avif 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/76ea5/iPad_Wizard_Installation_Others_03.avif 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/b105c/iPad_Wizard_Installation_Others_03.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/a0b58/iPad_Wizard_Installation_Others_03.webp 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/bc10c/iPad_Wizard_Installation_Others_03.webp 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/966d8/iPad_Wizard_Installation_Others_03.webp 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/445df/iPad_Wizard_Installation_Others_03.webp 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/78de1/iPad_Wizard_Installation_Others_03.webp 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/03b06/iPad_Wizard_Installation_Others_03.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/81c8e/iPad_Wizard_Installation_Others_03.png 230w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/08a84/iPad_Wizard_Installation_Others_03.png 460w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/c0255/iPad_Wizard_Installation_Others_03.png 920w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/b1001/iPad_Wizard_Installation_Others_03.png 1380w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/161ec/iPad_Wizard_Installation_Others_03.png 1840w", "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/49853/iPad_Wizard_Installation_Others_03.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d94ee47c9332a8bcb7ef05c9f1ee47e9/c0255/iPad_Wizard_Installation_Others_03.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cecff90558c8c44d937307fbf8c274f1/49853/iPad_Wizard_Installation_Others_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBklEQVQoz52T0W7DIAxF+f+vnNQ9bAk2BhKMAZNJQU2jVVq6njdf+Vq2MebL0jzD5+0GADnnuDJEmYPYkBMzcxIRAEBEEeGdnDMRTdNkKMSUUs45pVRry1In8BQTFy2l1lpba8PTWqt3WmuqahABALY7vXew8xLD9id9x3jvl2U5YlXFHVXtVxjnXIxx27aRrarWWufcUe7CDABHrKrOuRBCfwHTWiulnCVrLSK+ZH5eBiKGcLGwgflV7H9tP5uJyHv/jnnMTETvzNx7R0Qiemfmrt1aYObj5a/bPp8EOcychnLo56t8nOf4KwCwrqkWmbx8TMs3LlKK22FmEfHexxhHcghhiD/n6XHZ/XvItgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cecff90558c8c44d937307fbf8c274f1/e4706/iPad_Wizard_Installation_Others_04.avif 230w", "/en/static/cecff90558c8c44d937307fbf8c274f1/d1af7/iPad_Wizard_Installation_Others_04.avif 460w", "/en/static/cecff90558c8c44d937307fbf8c274f1/7f308/iPad_Wizard_Installation_Others_04.avif 920w", "/en/static/cecff90558c8c44d937307fbf8c274f1/e1c99/iPad_Wizard_Installation_Others_04.avif 1380w", "/en/static/cecff90558c8c44d937307fbf8c274f1/76ea5/iPad_Wizard_Installation_Others_04.avif 1840w", "/en/static/cecff90558c8c44d937307fbf8c274f1/b105c/iPad_Wizard_Installation_Others_04.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cecff90558c8c44d937307fbf8c274f1/a0b58/iPad_Wizard_Installation_Others_04.webp 230w", "/en/static/cecff90558c8c44d937307fbf8c274f1/bc10c/iPad_Wizard_Installation_Others_04.webp 460w", "/en/static/cecff90558c8c44d937307fbf8c274f1/966d8/iPad_Wizard_Installation_Others_04.webp 920w", "/en/static/cecff90558c8c44d937307fbf8c274f1/445df/iPad_Wizard_Installation_Others_04.webp 1380w", "/en/static/cecff90558c8c44d937307fbf8c274f1/78de1/iPad_Wizard_Installation_Others_04.webp 1840w", "/en/static/cecff90558c8c44d937307fbf8c274f1/03b06/iPad_Wizard_Installation_Others_04.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cecff90558c8c44d937307fbf8c274f1/81c8e/iPad_Wizard_Installation_Others_04.png 230w", "/en/static/cecff90558c8c44d937307fbf8c274f1/08a84/iPad_Wizard_Installation_Others_04.png 460w", "/en/static/cecff90558c8c44d937307fbf8c274f1/c0255/iPad_Wizard_Installation_Others_04.png 920w", "/en/static/cecff90558c8c44d937307fbf8c274f1/b1001/iPad_Wizard_Installation_Others_04.png 1380w", "/en/static/cecff90558c8c44d937307fbf8c274f1/161ec/iPad_Wizard_Installation_Others_04.png 1840w", "/en/static/cecff90558c8c44d937307fbf8c274f1/49853/iPad_Wizard_Installation_Others_04.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cecff90558c8c44d937307fbf8c274f1/c0255/iPad_Wizard_Installation_Others_04.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Wählen Sie Ihr Kameramodell, z.B. `}<strong parentName="li">{`IN-8015FullHD`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e18dae22312e04868d5382889a8dad59/49853/iPad_Wizard_Installation_Others_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVQoz4WSXW7bMBCEfbT2sUCA9lDpJfJQBLlGexQjgKM6pigtaXKXXP6spEJybVhujA7mYTDEB+4S3Kj+2HXwu2kAIKVEMZkwxDRIHWodShGREZGIgshYl1JkJAyNspujxxhjSinGWEU4FeMzUg7hr2MsXWf63sRYLiXHbLFujAEAmM6SKujZuWtHY9AYvCm9ixvnHBFN0zQuqlW854udizRPUYjydX/yxlqLiNM0DcNwAzsXEZNS0DRKa2stfQADwHjWDRxC2W532+1OKdjvO+fWsIiUUj6EvWfEZC2+v3d975SCedVreFpLZLiBX1/flAKtTdMcvF/D41r/Pth+r9vWaG20trc734MJOdDsSAl9CDiHU0P4PxiO3BrWi1uTWrjk+ejuzs4xEz/+LJ+e5OFZvvyQby/y9WUOD8/y+Um+/yopzF/l7s2Hnnctv+nZzeJT3rV8gPXYlx82iARk9ByQU0gpJJ7XZg6LlxDpDOecmRkAQoi1ZO3y3hTAUko15mitY045F+cQMeRcmJP3xJxzLn8Aft1Ue8ENYiYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e18dae22312e04868d5382889a8dad59/e4706/iPad_Wizard_Installation_Others_05.avif 230w", "/en/static/e18dae22312e04868d5382889a8dad59/d1af7/iPad_Wizard_Installation_Others_05.avif 460w", "/en/static/e18dae22312e04868d5382889a8dad59/7f308/iPad_Wizard_Installation_Others_05.avif 920w", "/en/static/e18dae22312e04868d5382889a8dad59/e1c99/iPad_Wizard_Installation_Others_05.avif 1380w", "/en/static/e18dae22312e04868d5382889a8dad59/76ea5/iPad_Wizard_Installation_Others_05.avif 1840w", "/en/static/e18dae22312e04868d5382889a8dad59/b105c/iPad_Wizard_Installation_Others_05.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e18dae22312e04868d5382889a8dad59/a0b58/iPad_Wizard_Installation_Others_05.webp 230w", "/en/static/e18dae22312e04868d5382889a8dad59/bc10c/iPad_Wizard_Installation_Others_05.webp 460w", "/en/static/e18dae22312e04868d5382889a8dad59/966d8/iPad_Wizard_Installation_Others_05.webp 920w", "/en/static/e18dae22312e04868d5382889a8dad59/445df/iPad_Wizard_Installation_Others_05.webp 1380w", "/en/static/e18dae22312e04868d5382889a8dad59/78de1/iPad_Wizard_Installation_Others_05.webp 1840w", "/en/static/e18dae22312e04868d5382889a8dad59/03b06/iPad_Wizard_Installation_Others_05.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e18dae22312e04868d5382889a8dad59/81c8e/iPad_Wizard_Installation_Others_05.png 230w", "/en/static/e18dae22312e04868d5382889a8dad59/08a84/iPad_Wizard_Installation_Others_05.png 460w", "/en/static/e18dae22312e04868d5382889a8dad59/c0255/iPad_Wizard_Installation_Others_05.png 920w", "/en/static/e18dae22312e04868d5382889a8dad59/b1001/iPad_Wizard_Installation_Others_05.png 1380w", "/en/static/e18dae22312e04868d5382889a8dad59/161ec/iPad_Wizard_Installation_Others_05.png 1840w", "/en/static/e18dae22312e04868d5382889a8dad59/49853/iPad_Wizard_Installation_Others_05.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e18dae22312e04868d5382889a8dad59/c0255/iPad_Wizard_Installation_Others_05.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bfc8c5e3f4d5d779909ea71686c40838/49853/iPad_Wizard_Installation_Others_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABV0lEQVQoz5VS7W7kIAzM+7/m3knb5AgBg7+BU7JNtNuqVTuaHyPLg4080/3ftizhz+0WQmDmghxrI2luzaypunvPueRc3LsdRfdeoN7ucdoyICIzI6K5E2vMAkVqfSeiplRSqoh6FQklgk3rGkII44SbQ6aUXpgz58yvRcwJp5RSKWWM0Q+Yec70Q04xRgAYY7TWns0A/BWPRU5zCKGfMPOyd9CybPMc5nldljjP68n49haWJQJQAZrcXVUvc2sto4maiPCBZ2HmZtaaZ+536NN4Re+9IhPRw3AJRBSRq01sAI+pf0KssgIL7zbVx+D3J9z9YQYZd/hkbq1V5P2SJLiv8BC7PtKgIqZiBW0r9tH8/ameLk+Q6eOf3dsv7vzN5D1GPzFfCWvuFfaVrpxcrQCXOEMiIkQUQqgVTWVO8nfVAKpq67rFmIhYRFMCgCqiRJxzIRIR/Q/CNmOU9jH6nQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfc8c5e3f4d5d779909ea71686c40838/e4706/iPad_Wizard_Installation_Others_06.avif 230w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/d1af7/iPad_Wizard_Installation_Others_06.avif 460w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/7f308/iPad_Wizard_Installation_Others_06.avif 920w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/e1c99/iPad_Wizard_Installation_Others_06.avif 1380w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/76ea5/iPad_Wizard_Installation_Others_06.avif 1840w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/b105c/iPad_Wizard_Installation_Others_06.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bfc8c5e3f4d5d779909ea71686c40838/a0b58/iPad_Wizard_Installation_Others_06.webp 230w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/bc10c/iPad_Wizard_Installation_Others_06.webp 460w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/966d8/iPad_Wizard_Installation_Others_06.webp 920w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/445df/iPad_Wizard_Installation_Others_06.webp 1380w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/78de1/iPad_Wizard_Installation_Others_06.webp 1840w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/03b06/iPad_Wizard_Installation_Others_06.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfc8c5e3f4d5d779909ea71686c40838/81c8e/iPad_Wizard_Installation_Others_06.png 230w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/08a84/iPad_Wizard_Installation_Others_06.png 460w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/c0255/iPad_Wizard_Installation_Others_06.png 920w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/b1001/iPad_Wizard_Installation_Others_06.png 1380w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/161ec/iPad_Wizard_Installation_Others_06.png 1840w", "/en/static/bfc8c5e3f4d5d779909ea71686c40838/49853/iPad_Wizard_Installation_Others_06.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bfc8c5e3f4d5d779909ea71686c40838/c0255/iPad_Wizard_Installation_Others_06.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Wählen Sie aus, ob Ihre Kamera über `}<strong parentName="li">{`WiFi`}</strong>{` angeschlossen werden soll oder ob Sie ein Ethernet-Kabel verwenden werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/49853/iPad_Wizard_Installation_Others_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABu0lEQVQoz41S7WrbQBDUu7U/C4G8U6EPkR8l7WOUPkVLKU2lyJLtWNaebm/vbu9jzyqyiYkNIR3mx7DL3OwtWz2Ner+HvusAIIRgfVC2+CA5S5aDSCllJrJErpRZpOS8kMiunlSlDXnvQwjeexHhkDQlz5k5IVpEa4xTCgEQ0WlNxjjmHKOgk0opAID5GSJlGFTf7zab/eNj3zR9XXd13T08tHXdt+2mbTebzX69HoadqhDRWjvP8+GInEUpGkdUik7c7VTT9E3TjSNOk1WKAAyAUYqqaZqIaJ7nUsrJTBRe0vnsfXYuXdWJwmIGgMMzchZj+ExEv17vYEREj+hftozhSkRSSq+ZtXZNveq6LYBGdFf+ar6ESLlK/vP7788fv/RkiPg6+XCJq+RjuNXanR76X7NGHjWDZoUBNI/Tok+c8HUzGmbLH7+ld3dycy8fPsvtV7n9soibe3l/J5++p+AY8fU/b0dud2E1LOyOPOl2CNvxrbG95eA8Ow5uGWThUQfHzl6azxdWRBwxHXunSziv57ztc7GKMTIzADjnc4oDxrVKQCmlrJSeJmQOMSZEInIxJuZgjGWOMaZ/s5BSbZvLaUYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9cb90430382ad9b497cbbc9401cfa2c/e4706/iPad_Wizard_Installation_Others_07.avif 230w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/d1af7/iPad_Wizard_Installation_Others_07.avif 460w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/7f308/iPad_Wizard_Installation_Others_07.avif 920w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/e1c99/iPad_Wizard_Installation_Others_07.avif 1380w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/76ea5/iPad_Wizard_Installation_Others_07.avif 1840w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/b105c/iPad_Wizard_Installation_Others_07.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e9cb90430382ad9b497cbbc9401cfa2c/a0b58/iPad_Wizard_Installation_Others_07.webp 230w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/bc10c/iPad_Wizard_Installation_Others_07.webp 460w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/966d8/iPad_Wizard_Installation_Others_07.webp 920w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/445df/iPad_Wizard_Installation_Others_07.webp 1380w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/78de1/iPad_Wizard_Installation_Others_07.webp 1840w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/03b06/iPad_Wizard_Installation_Others_07.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9cb90430382ad9b497cbbc9401cfa2c/81c8e/iPad_Wizard_Installation_Others_07.png 230w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/08a84/iPad_Wizard_Installation_Others_07.png 460w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/c0255/iPad_Wizard_Installation_Others_07.png 920w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/b1001/iPad_Wizard_Installation_Others_07.png 1380w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/161ec/iPad_Wizard_Installation_Others_07.png 1840w", "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/49853/iPad_Wizard_Installation_Others_07.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e9cb90430382ad9b497cbbc9401cfa2c/c0255/iPad_Wizard_Installation_Others_07.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f033d852b92b110cffd623542440b7ff/49853/iPad_Wizard_Installation_Others_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABrElEQVQoz42SW27bMBBFtbf2s0CA7KlAF5GPItlG0YW0iGFFkiXLHL6G5AwfsgpLjhC3SNuL+3E5gwPOEKyOQp+EaNsWAJjZBVZ+Ii45lbT4fJ4RHaKfpnmt5Dwh+mZQlbYYQmDmEEIuhThJG31IzJk4hxCt9VIaKY21PoTInJlz5KwwV1ICAMyvyrmgpfGouu7YdWPbDrtds7ltj30vmmYYBkBLlTHGOTfP83lRzsVakhIB7OrTSe/3XV0fhDBrfQ3WUqWUQsR5nqdp2mBE3uxc9D55n4wJa8u5iMhXGADOr1rhtzYmLFg4dL3W/m2rKqWklP4Ct81w6EatvRDGWr6B51uVMv0Gv9Tdzx/PANc9b+Dzrf68ue9Pu+e9OKl15/+CtSGhCTRJE5VNoBmWI2hS5n3YWCJHn7+lDw/l7rF8+lrun/L90yXcPZaPD+XL98SejHl/515QfeSX8eJm8ZrrkXvxr7GDI/aBPLG/DHLxktmTd7fw9sOmUjwSLr31k2zPg7iFa7GKMRIRAHgfcoqjiZ1MgCmlLKVWyhBxjMkYRPQxJiK21hHFGNMv6udTEPDqgtgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f033d852b92b110cffd623542440b7ff/e4706/iPad_Wizard_Installation_Others_08.avif 230w", "/en/static/f033d852b92b110cffd623542440b7ff/d1af7/iPad_Wizard_Installation_Others_08.avif 460w", "/en/static/f033d852b92b110cffd623542440b7ff/7f308/iPad_Wizard_Installation_Others_08.avif 920w", "/en/static/f033d852b92b110cffd623542440b7ff/e1c99/iPad_Wizard_Installation_Others_08.avif 1380w", "/en/static/f033d852b92b110cffd623542440b7ff/76ea5/iPad_Wizard_Installation_Others_08.avif 1840w", "/en/static/f033d852b92b110cffd623542440b7ff/b105c/iPad_Wizard_Installation_Others_08.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f033d852b92b110cffd623542440b7ff/a0b58/iPad_Wizard_Installation_Others_08.webp 230w", "/en/static/f033d852b92b110cffd623542440b7ff/bc10c/iPad_Wizard_Installation_Others_08.webp 460w", "/en/static/f033d852b92b110cffd623542440b7ff/966d8/iPad_Wizard_Installation_Others_08.webp 920w", "/en/static/f033d852b92b110cffd623542440b7ff/445df/iPad_Wizard_Installation_Others_08.webp 1380w", "/en/static/f033d852b92b110cffd623542440b7ff/78de1/iPad_Wizard_Installation_Others_08.webp 1840w", "/en/static/f033d852b92b110cffd623542440b7ff/03b06/iPad_Wizard_Installation_Others_08.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f033d852b92b110cffd623542440b7ff/81c8e/iPad_Wizard_Installation_Others_08.png 230w", "/en/static/f033d852b92b110cffd623542440b7ff/08a84/iPad_Wizard_Installation_Others_08.png 460w", "/en/static/f033d852b92b110cffd623542440b7ff/c0255/iPad_Wizard_Installation_Others_08.png 920w", "/en/static/f033d852b92b110cffd623542440b7ff/b1001/iPad_Wizard_Installation_Others_08.png 1380w", "/en/static/f033d852b92b110cffd623542440b7ff/161ec/iPad_Wizard_Installation_Others_08.png 1840w", "/en/static/f033d852b92b110cffd623542440b7ff/49853/iPad_Wizard_Installation_Others_08.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f033d852b92b110cffd623542440b7ff/c0255/iPad_Wizard_Installation_Others_08.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/43224166112c97469ad424f75d2e4ef6/49853/iPad_Wizard_Installation_Others_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1klEQVQoz4VSbYrcMAzN3dqfhcLeqdBD7I/SXqP0FKVQ2CWJJ9nMTPwly7ZsR56UzOz3svTxMM+WniUjNwdpZymHYVBKpZR8TMZXSlzKPWtdET1iqHUthXPmZamIXux1Yx3GGFNKMUZmplQsFkpbUkocY3YuSGm1BtwS8/l8WUoFz43WSim1PoC5zkctxCTEtNvt23a8vRVtu2vb4eam77pRiGkYDl03SmkaAPDer+t6OoO5KuXadmjboeu2VYi780VT3499f9d1Y9eNbTtojY0xBhHXda21nk6nZWHEFEK5EDEBROciIs2ztTbEuDyGNrNS6vSAZWHnyDkCiIhpPpqdmPZ7Oez2f37/nWeDmC4JzlHDzKWUt+YLz5WDUk5r3FrYuniKNutLMNfnYbDBGC8lSAlaueehzXx6iVeVtXZSWms8QFQS4FXl98wWSFqSJs46zDqos1aWlCUD75vBEXn68rN8uObP3/nTt3r1g682sW0/XvPXXyUFAnj/zZOk/pDEkcSRdhsvOvXHNMn/tR09pRApUApbIxvPOgUK/qX58YdV5oCED3N6PlXEp/ndm3PORKSUCiEuJR8hj7ooLKUsWltjgCjlXAAQMeRciJJznijnXP4Bw/pQ6kk25fYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/43224166112c97469ad424f75d2e4ef6/e4706/iPad_Wizard_Installation_Others_09.avif 230w", "/en/static/43224166112c97469ad424f75d2e4ef6/d1af7/iPad_Wizard_Installation_Others_09.avif 460w", "/en/static/43224166112c97469ad424f75d2e4ef6/7f308/iPad_Wizard_Installation_Others_09.avif 920w", "/en/static/43224166112c97469ad424f75d2e4ef6/e1c99/iPad_Wizard_Installation_Others_09.avif 1380w", "/en/static/43224166112c97469ad424f75d2e4ef6/76ea5/iPad_Wizard_Installation_Others_09.avif 1840w", "/en/static/43224166112c97469ad424f75d2e4ef6/b105c/iPad_Wizard_Installation_Others_09.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/43224166112c97469ad424f75d2e4ef6/a0b58/iPad_Wizard_Installation_Others_09.webp 230w", "/en/static/43224166112c97469ad424f75d2e4ef6/bc10c/iPad_Wizard_Installation_Others_09.webp 460w", "/en/static/43224166112c97469ad424f75d2e4ef6/966d8/iPad_Wizard_Installation_Others_09.webp 920w", "/en/static/43224166112c97469ad424f75d2e4ef6/445df/iPad_Wizard_Installation_Others_09.webp 1380w", "/en/static/43224166112c97469ad424f75d2e4ef6/78de1/iPad_Wizard_Installation_Others_09.webp 1840w", "/en/static/43224166112c97469ad424f75d2e4ef6/03b06/iPad_Wizard_Installation_Others_09.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/43224166112c97469ad424f75d2e4ef6/81c8e/iPad_Wizard_Installation_Others_09.png 230w", "/en/static/43224166112c97469ad424f75d2e4ef6/08a84/iPad_Wizard_Installation_Others_09.png 460w", "/en/static/43224166112c97469ad424f75d2e4ef6/c0255/iPad_Wizard_Installation_Others_09.png 920w", "/en/static/43224166112c97469ad424f75d2e4ef6/b1001/iPad_Wizard_Installation_Others_09.png 1380w", "/en/static/43224166112c97469ad424f75d2e4ef6/161ec/iPad_Wizard_Installation_Others_09.png 1840w", "/en/static/43224166112c97469ad424f75d2e4ef6/49853/iPad_Wizard_Installation_Others_09.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/43224166112c97469ad424f75d2e4ef6/c0255/iPad_Wizard_Installation_Others_09.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c216926c3f266503772118e0dd942166/49853/iPad_Wizard_Installation_Others_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByklEQVQoz42STW7bMBCFdbhs22x7oQI9RBZNFz1F0SMECNCgKGxX/5Zli6LIITkccmQVsmojDmC0D28xfMMP/E3abjh0XVVVQgjvvXG+N6PzHANT4BD4eJwALIAdxynGOYlxBDDFrk8GDc45771zLjKjDwqCw0jERGwtaW27Tva91tpa64nY+0g0KstJ3wshxHQWR27bvix3WVYXRZOm5XqdpWm1FFlWl+Wuqtqy3LWtSJRSxphpmo4nxchSmv1enoBqmV0U2yyr83yb5/XJ28NhkNIkUkoAmKZpHMcFBvAA3tpgbTCGds1BdGoZnsOwzJlhIcTxrBhZa3xlt1ln6e/iTbgUCTOHEG7DWBbNZp1q7QDwTSuZrsU8vm73Paw3+cuPX8/PL3UjhsFewcdrvVlZSlitsiwtV5vy6ef2f+FBYTegGFAqEoM/SGo6L/4mt2GlEQ1+/BbuHvj+kd995g9fZ79/5PsvfPfAn74Hb1Gp22duOsxbX+xnV4fZ+anO977p/rVtZ9BbhxZnm3mIFv3J1lzDlx82MltAOPWWn3C5nstTXcKEiBBRCGGti4H2iuo+CAghxL4fpFSInigoBQCWKCB6rQ0iEYU/5W1PnghJuyMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c216926c3f266503772118e0dd942166/e4706/iPad_Wizard_Installation_Others_10.avif 230w", "/en/static/c216926c3f266503772118e0dd942166/d1af7/iPad_Wizard_Installation_Others_10.avif 460w", "/en/static/c216926c3f266503772118e0dd942166/7f308/iPad_Wizard_Installation_Others_10.avif 920w", "/en/static/c216926c3f266503772118e0dd942166/e1c99/iPad_Wizard_Installation_Others_10.avif 1380w", "/en/static/c216926c3f266503772118e0dd942166/76ea5/iPad_Wizard_Installation_Others_10.avif 1840w", "/en/static/c216926c3f266503772118e0dd942166/b105c/iPad_Wizard_Installation_Others_10.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c216926c3f266503772118e0dd942166/a0b58/iPad_Wizard_Installation_Others_10.webp 230w", "/en/static/c216926c3f266503772118e0dd942166/bc10c/iPad_Wizard_Installation_Others_10.webp 460w", "/en/static/c216926c3f266503772118e0dd942166/966d8/iPad_Wizard_Installation_Others_10.webp 920w", "/en/static/c216926c3f266503772118e0dd942166/445df/iPad_Wizard_Installation_Others_10.webp 1380w", "/en/static/c216926c3f266503772118e0dd942166/78de1/iPad_Wizard_Installation_Others_10.webp 1840w", "/en/static/c216926c3f266503772118e0dd942166/03b06/iPad_Wizard_Installation_Others_10.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c216926c3f266503772118e0dd942166/81c8e/iPad_Wizard_Installation_Others_10.png 230w", "/en/static/c216926c3f266503772118e0dd942166/08a84/iPad_Wizard_Installation_Others_10.png 460w", "/en/static/c216926c3f266503772118e0dd942166/c0255/iPad_Wizard_Installation_Others_10.png 920w", "/en/static/c216926c3f266503772118e0dd942166/b1001/iPad_Wizard_Installation_Others_10.png 1380w", "/en/static/c216926c3f266503772118e0dd942166/161ec/iPad_Wizard_Installation_Others_10.png 1840w", "/en/static/c216926c3f266503772118e0dd942166/49853/iPad_Wizard_Installation_Others_10.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c216926c3f266503772118e0dd942166/c0255/iPad_Wizard_Installation_Others_10.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Sobald Sie `}{`[den WPS-Modus aktiviert haben]`}{` (/Quick_Installation/Set_Up_A_Wireless_Connection/#plugplay-wps) sowohl auf Ihrer Kamera als auch auf dem Router klicken Sie auf `}<strong parentName="li">{`Search`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie die Option `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/49853/iPad_Wizard_Installation_Others_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABv0lEQVQoz4WS4W7UMAyA+27wcxIS74S0h9iPCV4D8Q4IIQ1p7Ljdtd21cZrESewk7hW15eAObWB9slxbX5u6rQ7K9Ert93sAYGYfWYeRkpQsOUspcjxOznnEMI5TKWtzRPSPT7oyDmOMzBxjFBHibDDFWJhnEKNzQWsDYJZbEHMhKimJ8aXSGgBgOoXIqMHVddc0XV13u1378LDbbPabzZy322YZ9U3Ta3CVtdZ7P03TcYlSxJgA4Fa0Rq2xrg913Q2DPzXnbEyohmFAxGmaxnFcZURC5BXv0zlLc83sHM0yABxPUYo4RyuI3Db9U6ucI2sjKKuUXbWVSkRyzs/KztF+1367uzcmaI3f73+0TXchT5chMp7Lbdt/+fxVKeMc9d1gTDifVsfL+OvJiGxt0NrpZUPno3/JxpIaCAYCQ8owGNZmrsHQYF+WrSPy9O5jfnUjb97L1a28/TBzdTtfvr6R60+ZA1n78ju3irYHfuxmdgtrve24Vf87dvTEIVIgDvNBZpaaAwV/Kf/+w0aRgISnbZ1/GMQ/W/wlp5SICABCiCWnzqZaZ8Ccc9HaDIMl4pSytYgYUspE7JwnSinln3cFUnrhZU7LAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a94e9fb48bcdfff86cae335e2cc4159f/e4706/iPad_Wizard_Installation_Others_11.avif 230w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/d1af7/iPad_Wizard_Installation_Others_11.avif 460w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/7f308/iPad_Wizard_Installation_Others_11.avif 920w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/e1c99/iPad_Wizard_Installation_Others_11.avif 1380w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/76ea5/iPad_Wizard_Installation_Others_11.avif 1840w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/b105c/iPad_Wizard_Installation_Others_11.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a94e9fb48bcdfff86cae335e2cc4159f/a0b58/iPad_Wizard_Installation_Others_11.webp 230w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/bc10c/iPad_Wizard_Installation_Others_11.webp 460w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/966d8/iPad_Wizard_Installation_Others_11.webp 920w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/445df/iPad_Wizard_Installation_Others_11.webp 1380w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/78de1/iPad_Wizard_Installation_Others_11.webp 1840w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/03b06/iPad_Wizard_Installation_Others_11.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a94e9fb48bcdfff86cae335e2cc4159f/81c8e/iPad_Wizard_Installation_Others_11.png 230w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/08a84/iPad_Wizard_Installation_Others_11.png 460w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/c0255/iPad_Wizard_Installation_Others_11.png 920w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/b1001/iPad_Wizard_Installation_Others_11.png 1380w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/161ec/iPad_Wizard_Installation_Others_11.png 1840w", "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/49853/iPad_Wizard_Installation_Others_11.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a94e9fb48bcdfff86cae335e2cc4159f/c0255/iPad_Wizard_Installation_Others_11.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[12]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/49853/iPad_Wizard_Installation_Others_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABw0lEQVQoz4WSS27bMBCGdbhsg2xzoQI9RBZpFzlF0SsU7QGMWA9LlvjQcEjO8GUVshLDRpv2x7egfuKTRgSro5gnIdq2lVIys/WsXSHOKeZ45nRaEC2iK2XZmpQKomsGXc0GvffM7L1PORNHZYLzkTkRJ++DMU4pUAqMcd4H5sScAieNqVJKSimX96SU0dB41F137LqxbYfdrjlT73ZN2x77XjTNMAwSDVUAYK1dluV0TkrZGFIKpTQb0zS/vnZ13Qsxb70QoBQaQ5XWGhGXZSmlXGRE3rA2OBedi0qZ6xKR32Qp5ek9m3xBKdTaArj+MAA4AHe9W+WcY4x/l5Gbpq/3B7Oegmr2h6EX2zff5OU2OZfrd7ft8PPHr3FUUkJTH6ZJ38in2/wxtqn33dBP6/zKXpv/kmcgMZOaWUOYlB+lk5qkXksNH8tgiCx9+hbvnvLDl3z/nB9fyuNLuX/OD1/z3VP+/D2yI4CP/7kXtD9yPa6008q23o/ci/+N7S2x8+Roxa6P5IjPOHsrX25Yydkh4XlvuxKX40G8LN7KKoRARFJK53yKYYTQqSgxxpiUmrUGIg4hAiCiCyESsTGWKIQQfwNNiFI+DB2IyQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/e4706/iPad_Wizard_Installation_Others_12.avif 230w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/d1af7/iPad_Wizard_Installation_Others_12.avif 460w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/7f308/iPad_Wizard_Installation_Others_12.avif 920w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/e1c99/iPad_Wizard_Installation_Others_12.avif 1380w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/76ea5/iPad_Wizard_Installation_Others_12.avif 1840w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/b105c/iPad_Wizard_Installation_Others_12.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/a0b58/iPad_Wizard_Installation_Others_12.webp 230w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/bc10c/iPad_Wizard_Installation_Others_12.webp 460w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/966d8/iPad_Wizard_Installation_Others_12.webp 920w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/445df/iPad_Wizard_Installation_Others_12.webp 1380w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/78de1/iPad_Wizard_Installation_Others_12.webp 1840w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/03b06/iPad_Wizard_Installation_Others_12.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/81c8e/iPad_Wizard_Installation_Others_12.png 230w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/08a84/iPad_Wizard_Installation_Others_12.png 460w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/c0255/iPad_Wizard_Installation_Others_12.png 920w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/b1001/iPad_Wizard_Installation_Others_12.png 1380w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/161ec/iPad_Wizard_Installation_Others_12.png 1840w", "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/49853/iPad_Wizard_Installation_Others_12.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0304ca9d4faaa8304ec819f8ad0c88cd/c0255/iPad_Wizard_Installation_Others_12.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[13]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/360d1544730914543035ed71ca57d9d9/49853/iPad_Wizard_Installation_Others_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIklEQVQoz62Ty27EIAxF+f+f7CazoBkGCC/bYFMlNBHTxbRVexaWfPGVbQkrfXfr/b4sizEGAEIqqyshFYRPaq3WWuccEQ0FEb1zb7d35baQcwaAnHOtDZAKSWOpB0QUYxzmlFI94dYysno8jDGmnwjz5p33XkSGggdEhIi9T4UiynsfY9yzA2Ze11VrTUTtgCfaCbdWsClrbQih987Mw6y1XpYFAEZ6jTDSgTAD8W42xsgJM4+tLgWgpBRTijklmWAW1Vojotk8LdYb9wTkYwkZYsYm82NX/ZlryAHUvmH30MMRS302yzNfOr/mG7OcUab0nzq/3vlPY//IfP2w33VGxFKKMSalXAnfPd4sPgIQ7cdkrS2l7GfkfQhhFG/bNsQPJcBzoYjvhrQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/360d1544730914543035ed71ca57d9d9/e4706/iPad_Wizard_Installation_Others_13.avif 230w", "/en/static/360d1544730914543035ed71ca57d9d9/d1af7/iPad_Wizard_Installation_Others_13.avif 460w", "/en/static/360d1544730914543035ed71ca57d9d9/7f308/iPad_Wizard_Installation_Others_13.avif 920w", "/en/static/360d1544730914543035ed71ca57d9d9/e1c99/iPad_Wizard_Installation_Others_13.avif 1380w", "/en/static/360d1544730914543035ed71ca57d9d9/76ea5/iPad_Wizard_Installation_Others_13.avif 1840w", "/en/static/360d1544730914543035ed71ca57d9d9/b105c/iPad_Wizard_Installation_Others_13.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/360d1544730914543035ed71ca57d9d9/a0b58/iPad_Wizard_Installation_Others_13.webp 230w", "/en/static/360d1544730914543035ed71ca57d9d9/bc10c/iPad_Wizard_Installation_Others_13.webp 460w", "/en/static/360d1544730914543035ed71ca57d9d9/966d8/iPad_Wizard_Installation_Others_13.webp 920w", "/en/static/360d1544730914543035ed71ca57d9d9/445df/iPad_Wizard_Installation_Others_13.webp 1380w", "/en/static/360d1544730914543035ed71ca57d9d9/78de1/iPad_Wizard_Installation_Others_13.webp 1840w", "/en/static/360d1544730914543035ed71ca57d9d9/03b06/iPad_Wizard_Installation_Others_13.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/360d1544730914543035ed71ca57d9d9/81c8e/iPad_Wizard_Installation_Others_13.png 230w", "/en/static/360d1544730914543035ed71ca57d9d9/08a84/iPad_Wizard_Installation_Others_13.png 460w", "/en/static/360d1544730914543035ed71ca57d9d9/c0255/iPad_Wizard_Installation_Others_13.png 920w", "/en/static/360d1544730914543035ed71ca57d9d9/b1001/iPad_Wizard_Installation_Others_13.png 1380w", "/en/static/360d1544730914543035ed71ca57d9d9/161ec/iPad_Wizard_Installation_Others_13.png 1840w", "/en/static/360d1544730914543035ed71ca57d9d9/49853/iPad_Wizard_Installation_Others_13.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/360d1544730914543035ed71ca57d9d9/c0255/iPad_Wizard_Installation_Others_13.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[14]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie die Option `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0731e806e36292a64ea80cdc8c1d9535/49853/iPad_Wizard_Installation_Others_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABY0lEQVQoz5WSW25TMRCGzx77iFSpW0JiCX1ApeugT1QtC0CI9EISx9d4branOsdJKCEJ7Sc/jEbz/zO2Z/i9dIvlcjabGWMQMa5h4TEkQBjJORORtdY5R0Q5ZwBAROfsj4f54ELMOSNizlmkIHECIRYRKbW21kQkhOC9L6W01mqtLFJLWVMdVitjjNEtrbVdnHMOE6sJ730IARF3BYP3PqXUZd3YWtszIkJEsoWZiaiU8kdsrY0xqmqdhiylOOeYWU/Si0exMaZtqbU657rdfxlKKcz8Wrw326nOe9nWGk6IyFig+u9RVWZhlqH9Ta0VAFJKBEmrHOvcV+CAuH/WL6+3j3r3NJ7vz3r/tIm/PepPt73zMfHHr3p2qedX+uGzXlzrxZcxOL8ak59uNgtx4M76Zg503riMRnrwwXb+G/Fuw97XmYgAwBizXmdhmnt6sLSKyCx2AgCIyHsfY+zFIYSefAGdqWhnhTXpPAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0731e806e36292a64ea80cdc8c1d9535/e4706/iPad_Wizard_Installation_Others_14.avif 230w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/d1af7/iPad_Wizard_Installation_Others_14.avif 460w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/7f308/iPad_Wizard_Installation_Others_14.avif 920w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/e1c99/iPad_Wizard_Installation_Others_14.avif 1380w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/76ea5/iPad_Wizard_Installation_Others_14.avif 1840w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/b105c/iPad_Wizard_Installation_Others_14.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0731e806e36292a64ea80cdc8c1d9535/a0b58/iPad_Wizard_Installation_Others_14.webp 230w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/bc10c/iPad_Wizard_Installation_Others_14.webp 460w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/966d8/iPad_Wizard_Installation_Others_14.webp 920w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/445df/iPad_Wizard_Installation_Others_14.webp 1380w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/78de1/iPad_Wizard_Installation_Others_14.webp 1840w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/03b06/iPad_Wizard_Installation_Others_14.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0731e806e36292a64ea80cdc8c1d9535/81c8e/iPad_Wizard_Installation_Others_14.png 230w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/08a84/iPad_Wizard_Installation_Others_14.png 460w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/c0255/iPad_Wizard_Installation_Others_14.png 920w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/b1001/iPad_Wizard_Installation_Others_14.png 1380w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/161ec/iPad_Wizard_Installation_Others_14.png 1840w", "/en/static/0731e806e36292a64ea80cdc8c1d9535/49853/iPad_Wizard_Installation_Others_14.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0731e806e36292a64ea80cdc8c1d9535/c0255/iPad_Wizard_Installation_Others_14.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[15]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/49853/iPad_Wizard_Installation_Others_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRElEQVQoz7WT3UrDMBSA+/J6I2PeTB9Eb8QLBTfY3mCg4M12Ydc0PU3b89MkR9quA9HhhvgRDslJviQHkuQjK3ZZttlsjDFE5GrcAboaCfcws7W2KAoRGTJEVBT2fZsmRemapiGipmna1hNLhSKtjz3ee0R0PUQUQogxdjGEmkOS58YYoyMxBFVl5kEAgCzL8pEhAwAioqoJAFRV1Wk9IQQASNPUVTVS2yAjt8gtSReRpEZGYu99J1trnXOqerhSnudZtosx6HFijHvZGBNH+i266bu1XjzpZK5XL3q90Gnfmcz18lnv14OvifdeRL7LD686XejNSmdLvV11bbbshtOFPr6N8o/3OZEkfiWEfakhqj/SQvxN/tPJJ8n/UvMZ8uGFnXcyMyOiMaaum1Y4Bd5azh2JtLZn+FgA4JwbFpdlOSQ/Aeq9Z7DgXh7iAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/e4706/iPad_Wizard_Installation_Others_15.avif 230w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/d1af7/iPad_Wizard_Installation_Others_15.avif 460w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/7f308/iPad_Wizard_Installation_Others_15.avif 920w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/e1c99/iPad_Wizard_Installation_Others_15.avif 1380w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/76ea5/iPad_Wizard_Installation_Others_15.avif 1840w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/b105c/iPad_Wizard_Installation_Others_15.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/a0b58/iPad_Wizard_Installation_Others_15.webp 230w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/bc10c/iPad_Wizard_Installation_Others_15.webp 460w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/966d8/iPad_Wizard_Installation_Others_15.webp 920w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/445df/iPad_Wizard_Installation_Others_15.webp 1380w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/78de1/iPad_Wizard_Installation_Others_15.webp 1840w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/03b06/iPad_Wizard_Installation_Others_15.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/81c8e/iPad_Wizard_Installation_Others_15.png 230w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/08a84/iPad_Wizard_Installation_Others_15.png 460w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/c0255/iPad_Wizard_Installation_Others_15.png 920w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/b1001/iPad_Wizard_Installation_Others_15.png 1380w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/161ec/iPad_Wizard_Installation_Others_15.png 1840w", "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/49853/iPad_Wizard_Installation_Others_15.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7dbf2b4b2cf557c73fa4997c8ee87e5c/c0255/iPad_Wizard_Installation_Others_15.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[16]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7069b7aaef6fa4a42b0743547daa21fb/49853/iPad_Wizard_Installation_Others_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABeUlEQVQoz6WS30pcMRCH87JeCULfpvgKwiL2GeqNt+K9sgs9pzv5N5NMMpmclOO6h0qRCv74CJNkvoRAzHT0v4/H/X4PAKUUysWSclVtXbWrLr0PxESUeh+qS2u9tY5IL7+s8RFzzqWUnHNT5SIehZKkVLxHgOBcnCaYZ3AOrQ0hUM5Sq3pSYy0AwDin9+7dKohoShwjEWUiJsqICTEx1xhpngGRzHoU0RhjOQfgOM/zsoyV8cYpy7KWIcTDYU9ExjmHiH/L1gJi3Pr/YfSu0zQx8yoDwGaqqrU+eP/worvH/uOp3z2t41bsHvvDc7MARNmoqohscmsaImfi7z/rxU27um2Xu/btbuVyt04vbtr1vZRUfGAz3ke1x8gxsjKPeqa8cp4q86nHLO/Tmp42fGD3AT78T/4MX5M/evNXbw4hf0rePklXTcj4uodYEMvWirgVb4um1srMAJBSblLnUA9WAEWkWeudC8ylVgkBEVOtwlxiJOZaq/wBR+9Y0D8u+j4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7069b7aaef6fa4a42b0743547daa21fb/e4706/iPad_Wizard_Installation_Others_16.avif 230w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/d1af7/iPad_Wizard_Installation_Others_16.avif 460w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/7f308/iPad_Wizard_Installation_Others_16.avif 920w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/e1c99/iPad_Wizard_Installation_Others_16.avif 1380w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/76ea5/iPad_Wizard_Installation_Others_16.avif 1840w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/b105c/iPad_Wizard_Installation_Others_16.avif 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7069b7aaef6fa4a42b0743547daa21fb/a0b58/iPad_Wizard_Installation_Others_16.webp 230w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/bc10c/iPad_Wizard_Installation_Others_16.webp 460w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/966d8/iPad_Wizard_Installation_Others_16.webp 920w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/445df/iPad_Wizard_Installation_Others_16.webp 1380w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/78de1/iPad_Wizard_Installation_Others_16.webp 1840w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/03b06/iPad_Wizard_Installation_Others_16.webp 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7069b7aaef6fa4a42b0743547daa21fb/81c8e/iPad_Wizard_Installation_Others_16.png 230w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/08a84/iPad_Wizard_Installation_Others_16.png 460w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/c0255/iPad_Wizard_Installation_Others_16.png 920w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/b1001/iPad_Wizard_Installation_Others_16.png 1380w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/161ec/iPad_Wizard_Installation_Others_16.png 1840w", "/en/static/7069b7aaef6fa4a42b0743547daa21fb/49853/iPad_Wizard_Installation_Others_16.png 2224w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7069b7aaef6fa4a42b0743547daa21fb/c0255/iPad_Wizard_Installation_Others_16.png",
              "alt": "InstarVision iPad Installation Wizard",
              "title": "InstarVision iPad Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[17]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      